import { API_CUBIX_URL, CLIENT3_A } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";

export const getAllCompounds = (_queryParam, paginationInfo) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/compound/getBOM`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const deleteCompoundById = (_queryParam) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/user/study/removeByCompound?compoundId=${_queryParam.compoundId}&view=${_queryParam.view}&tsm=${_queryParam.tsm}`
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//getBOM data
export const getBOM = (_queryParam, paginationInfo) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/compound/getBOM`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

// getBOMFilter
export const getBOMFilter = (_queryParam, paginationInfo) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/compound/getBOMFilter`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//getLinks
export const getConnectionApi = (_queryParam) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/compound/getLinks`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

// get compound traceability
export const getAllUniqueCompounds = () => {
  return AjaxService.get(`${CLIENT3_A}/api/compound/getAllUniqueCompounds`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getDrugProductInvDetailsAPI = (_queryParam) => {
  return AjaxService.get(`${CLIENT3_A}/api/compound/getDrugProductInvDetails?type=${_queryParam.type}&value=${_queryParam.value}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};
