const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';
const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION';
const LOAD_GLOBALRISKMANAGEMENT = 'LOAD_GLOBALRISKMANAGEMENT'
const LOAD_GLOBALALERTMANAGEMENT = 'LOAD_GLOBALALERTMANAGEMENT'
const SET_THEME = 'SET_THEME';
const SET_NAVIGATION_DATA = 'SET_NAVIGATION_DATA';
const TOGGLE_MENU = 'TOGGLE_MENU';
const SEARCH = 'SEARCH';
const STUDY_DETAILS = 'STUDY_DETAILS';
const SET_CURRENT_SCREEN = 'SET_CURRENT_SCREEN';
const SET_SUPPLY_DATA = 'SET_SUPPLY_DATA';
const SET_RISK_LEVEL = 'SET_RISK_LEVEL';
const SET_MILESTONE_DATA = 'SET_MILESTONE_DATA';
const SET_ROLECONFIG_DATA = 'SET_ROLECONFIG_DATA';
const SET_USERROLECONFIG_DATA = 'SET_USERROLECONFIG_DATA';
const IS_LANDING = 'IS_LANDING';
const SHOW_FEEDBACK = 'SHOW_FEEDBACK';
const SELECTED_ITEM = 'SELECTED_ITEM';
const COMPOUND_LIST = 'COMPOUND_LIST';
const SELECTED_OPTION = 'SELECTED_OPTION';
const IS_EXPAND = 'IS_EXPAND'
const SELECTED_TYPE = 'SELECTED_TYPE'
const SET_ALERT_MATERIAL = "SET_ALERT_MATERIAL"
const NODE_ID = "NODE_TYPE"
const SET_COMPOUND_ID = "SET_COMPOUND_ID"
const SET_LINK_OBJECT = "SET_LINK_OBJECT"
const ACTUAL_SUBJECT = 'ACTUAL_SUBJECT'
const SHOW_CHART = 'SHOW_CHART'
const EXPECTED_RESOLUTION_SLIDE = "EXPECTED_RESOLUTION_SLIDE";
const TESTVALUESDATA = "TESTVALUESDATA";
const INITIAL_lOAD_OPEN_VERSION = "INITIAL_lOAD_OPEN_VERSION";
const SET_Filter_DATA = 'SET_Filter_DATA'
const SET_COMPOUND_LIST = 'SET_COMPOUND_LIST'
const SET_POTFOILIO = 'SET_POTFOILIO'
const SET_SUB_CATEGORY = 'SET_SUB_CATEGORY'
const RISK_ACTION = 'RISK_ACTION'
const CHECK_LIST_TOGGEL = 'CHECK_LIST_TOGGEL'
const FGDATA = "FGDATA";
const SET_SUMMARY_DATA = "SET_SUMMARY_DATA";
const TOGGLE_CLOSED = "TOGGLE_CLOSED";
const RESET_SHOW_CLOSED = "RESET_SHOW_CLOSED";
const FGCOUNTRYDATA = "FGCOUNTRYDATA";
const COMPLEXITY_LEVEL = "COMPLEXITY_LEVEL";
const RISK_ACTION_SUMMARY_FILTER = 'RISK_ACTION_SUMMARY_FILTER';
const GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED = "GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED";
const GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS = "GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS";
const GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED = "GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED";
const GET_DEMAND_AND_SUPPLY_TABLEDATA_NOTSTARTED = "GET_DEMAND_AND_SUPPLY_TABLEDATA_NOTSTARTED";
const GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED = "GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED";
const GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS = "GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS";
const GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED = "GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED";
const GET_INVENTORY_AND_SITE_SHIPEMENTDATA_NOTSTARTED = "GET_INVENTORY_AND_SITE_SHIPEMENTDATA_NOTSTARTED";

const GET_DRUG_PRODUCT_INVDETAILS_STARTED = "GET_DRUG_PRODUCT_INVDETAILS_STARTED";
const GET_DRUG_PRODUCT_INVDETAILS_SUCCESS = "GET_DRUG_PRODUCT_INVDETAILS_SUCCESS";
const GET_DRUG_PRODUCT_INVDETAILS_FAILED = "GET_DRUG_PRODUCT_INVDETAILS_FAILED";
const GET_DRUG_PRODUCT_INVDETAILS_NOTSTARTED = "GET_DRUG_PRODUCT_INVDETAILS_NOTSTARTED";
const GET_BCSR_TABLEDATA_STARTED = "GET_BCSR_TABLEDATA_STARTED";
const GET_BCSR_TABLEDATA_SUCCESS = "GET_BCSR_TABLEDATA_SUCCESS";
const GET_BCSR_TABLEDATA_FAILED = "GET_BCSR_TABLEDATA_FAILED";
const GET_BCSR_TABLEDATA_NOTSTARTED = "GET_BCSR_TABLEDATA_NOTSTARTED";
const ADD_BCSR_FORMDATA_STARTED = "ADD_BCSR_FORMDATA_STARTED";
const ADD_BCSR_FORMDATA_SUCCESS = "ADD_BCSR_FORMDATA_SUCCESS";
const ADD_BCSR_FORMDATA_FAILED = "ADD_BCSR_FORMDATA_FAILED";
const GET_ONE_BCSR_DATA_BYID_STARTED = "GET_ONE_BCSR_DATA_BYID_STARTED";
const GET_ONE_BCSR_DATA_BYID_SUCCESS = "GET_ONE_BCSR_DATA_BYID_SUCCESS";
const GET_ONE_BCSR_DATA_BYID_FAILED = "GET_ONE_BCSR_DATA_BYID_FAILED";
const GET_ONE_BCSR_DATA_BYID_NOTSTARTED = "GET_ONE_BCSR_DATA_BYID_NOTSTARTED";
const ADD_BCSR_FORMDATA_NOTSTARTED = "ADD_BCSR_FORMDATA_NOTSTARTED";
const UPDATE_BCSR_DATA_BYID_STARTED = "UPDATE_BCSR_DATA_BYID_STARTED";
const UPDATE_BCSR_DATA_BYID_SUCCESS = "UPDATE_BCSR_DATA_BYID_SUCCESS";
const UPDATE_BCSR_DATA_BYID_FAILED = "UPDATE_BCSR_DATA_BYID_FAILED";
const UPDATE_BCSR_DATA_BYID_NOTSTARTED = "UPDATE_BCSR_DATA_BYID_NOTSTARTED";

const BCSRRiskAssessmentFormDATA = "BCSRRiskAssessmentFormDATA";
const FILTER_ALERT_TABLE_DATA = "FILTER_ALERT_TABLE_DATA";
const TREND_GRAPH_TABLE_FILTER = "TREND_GRAPH_TABLE_FILTER";
const RISK_REGISTER_TABLE_DATA = "RISK_REGISTER_TABLE_DATA";

const GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED = "GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED";
const GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS = "GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS";
const GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED = "GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED";
const GET_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED = "GET_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED";
const UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED = "UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED";
const UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS = "UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS";
const UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED = "UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED"
const UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED = "UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED"
const GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED = "GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED";
const GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS = "GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS";
const GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED = "GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED";
const GET_PRODUCT_COMPLEXITY_TABLEDATA_NOTSTARTED = "GET_PRODUCT_COMPLEXITY_TABLEDATA_NOTSTARTED";
const GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED = "GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED";
const GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS = "GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS";
const GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED = "GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED";
const GET_SUPPLY_COMPLEXITY_TABLEDATA_NOTSTARTED = "GET_SUPPLY_COMPLEXITY_TABLEDATA_NOTSTARTED";
const UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED = "UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED";
const UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS = "UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS";
const UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED = "UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED"
const UPDATE_PRODUCT_COMPLEXITY_BYID_NOTSTARTED = "UPDATE_PRODUCT_COMPLEXITY_BYID_NOTSTARTED"
const UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED = "UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED";
const UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS = "UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS";
const UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED = "UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED"
const UPDATE_SUPPLY_COMPLEXITY_BYID_NOTSTARTED = "UPDATE_SUPPLY_COMPLEXITY_BYID_NOTSTARTED"

export {
    SHOW_LOADING,
    HIDE_LOADING,
    SHOW_NOTIFICATION,
    HIDE_NOTIFICATION,
    LOAD_GLOBALRISKMANAGEMENT,
    LOAD_GLOBALALERTMANAGEMENT,
    SET_THEME,
    SET_NAVIGATION_DATA,
    TOGGLE_MENU,
    SEARCH,
    STUDY_DETAILS,
    SET_RISK_LEVEL,
    SET_MILESTONE_DATA,
    SET_CURRENT_SCREEN,
    SET_SUPPLY_DATA,
    SET_ROLECONFIG_DATA,
    SET_USERROLECONFIG_DATA,
    IS_LANDING,
    SHOW_FEEDBACK,
    SELECTED_ITEM,
    COMPOUND_LIST,
    SELECTED_OPTION,
    IS_EXPAND,
    SELECTED_TYPE,
    SET_ALERT_MATERIAL,
    NODE_ID,
    SET_COMPOUND_ID,
    SET_LINK_OBJECT,
    ACTUAL_SUBJECT,
    SHOW_CHART,
    EXPECTED_RESOLUTION_SLIDE,
    TESTVALUESDATA,
    INITIAL_lOAD_OPEN_VERSION,
    SET_Filter_DATA,
    SET_COMPOUND_LIST,
    SET_POTFOILIO,
    SET_SUB_CATEGORY,
    RISK_ACTION,
    CHECK_LIST_TOGGEL,
    FGDATA,
    SET_SUMMARY_DATA,
    TOGGLE_CLOSED,
    RESET_SHOW_CLOSED,
    FGCOUNTRYDATA,
    COMPLEXITY_LEVEL,
    RISK_ACTION_SUMMARY_FILTER,
    GET_DEMAND_AND_SUPPLY_TABLEDATA_STARTED,
    GET_DEMAND_AND_SUPPLY_TABLEDATA_SUCCESS,
    GET_DEMAND_AND_SUPPLY_TABLEDATA_FAILED,
    GET_DEMAND_AND_SUPPLY_TABLEDATA_NOTSTARTED,
    GET_INVENTORY_AND_SITE_SHIPEMENTDATA_STARTED,
    GET_INVENTORY_AND_SITE_SHIPEMENTDATA_SUCCESS,
    GET_INVENTORY_AND_SITE_SHIPEMENTDATA_FAILED,
    GET_INVENTORY_AND_SITE_SHIPEMENTDATA_NOTSTARTED,
    GET_DRUG_PRODUCT_INVDETAILS_STARTED,
    GET_DRUG_PRODUCT_INVDETAILS_SUCCESS,
    GET_DRUG_PRODUCT_INVDETAILS_FAILED,
    GET_DRUG_PRODUCT_INVDETAILS_NOTSTARTED,
    GET_BCSR_TABLEDATA_STARTED,
    GET_BCSR_TABLEDATA_SUCCESS,
    GET_BCSR_TABLEDATA_FAILED,
    GET_BCSR_TABLEDATA_NOTSTARTED,
    ADD_BCSR_FORMDATA_STARTED,
    ADD_BCSR_FORMDATA_SUCCESS,
    ADD_BCSR_FORMDATA_FAILED,
    ADD_BCSR_FORMDATA_NOTSTARTED,
    UPDATE_BCSR_DATA_BYID_STARTED,
    UPDATE_BCSR_DATA_BYID_SUCCESS,
    UPDATE_BCSR_DATA_BYID_FAILED,
    UPDATE_BCSR_DATA_BYID_NOTSTARTED,
    GET_ONE_BCSR_DATA_BYID_STARTED,
    GET_ONE_BCSR_DATA_BYID_SUCCESS,
    GET_ONE_BCSR_DATA_BYID_FAILED,
    GET_ONE_BCSR_DATA_BYID_NOTSTARTED,

    BCSRRiskAssessmentFormDATA,
    FILTER_ALERT_TABLE_DATA,

    GET_STUDY_DESIGN_COMPLEXITY_BYID_STARTED,
    GET_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS,
    GET_STUDY_DESIGN_COMPLEXITY_BYID_FAILED,
    GET_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED,
    UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_STARTED,
    UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_SUCCESS,
    UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_FAILED,
    UPDATE_STUDY_DESIGN_COMPLEXITY_BYID_NOTSTARTED,
    GET_PRODUCT_COMPLEXITY_TABLEDATA_STARTED,
    GET_PRODUCT_COMPLEXITY_TABLEDATA_SUCCESS,
    GET_PRODUCT_COMPLEXITY_TABLEDATA_FAILED,
    GET_PRODUCT_COMPLEXITY_TABLEDATA_NOTSTARTED,
    GET_SUPPLY_COMPLEXITY_TABLEDATA_STARTED,
    GET_SUPPLY_COMPLEXITY_TABLEDATA_SUCCESS,
    GET_SUPPLY_COMPLEXITY_TABLEDATA_FAILED,
    GET_SUPPLY_COMPLEXITY_TABLEDATA_NOTSTARTED,
    UPDATE_PRODUCT_COMPLEXITY_BYID_STARTED,
    UPDATE_PRODUCT_COMPLEXITY_BYID_SUCCESS,
    UPDATE_PRODUCT_COMPLEXITY_BYID_FAILED,
    UPDATE_PRODUCT_COMPLEXITY_BYID_NOTSTARTED,
    UPDATE_SUPPLY_COMPLEXITY_BYID_STARTED,
    UPDATE_SUPPLY_COMPLEXITY_BYID_SUCCESS,
    UPDATE_SUPPLY_COMPLEXITY_BYID_FAILED,
    UPDATE_SUPPLY_COMPLEXITY_BYID_NOTSTARTED,
    TREND_GRAPH_TABLE_FILTER,
    RISK_REGISTER_TABLE_DATA
}