import { CLIENT3_A, CLIENT3_A_DEV_DEMO } from "../../constants/apiBaseUrl";
import { AjaxService } from "../../utils/AjaxService";

// get Country Summary
export const getCountrySummary = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/countryprofile/getCountrySummary`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


// get All DepotsByCountryAndStudy
export const getAllDepotsByCountryAndStudy = (_queryParam, body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/depotprofile/getAllDepotsByCountryAndStudy?country=${_queryParam?.country}`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


//  Inventory AtSitesForDigitalTwin
export const getInventoryAtSitesForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getInventoryAtSitesForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//   Inbound Shipments At Sites DigitalTwin
export const getInboundShipmentsAtSitesForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getInboundShipmentsAtSitesForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// Patient Enrollment and upcoming planned Visit
export const getPatientEnrollmentandVisitDetailsForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getPatientEnrollmentandVisitDetailsForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// get All Sites Info for DigitalTwin
export const getAllSitesInfoforDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getAllSitesInfoforDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


//inventory and site shipment togther in digital twin
export const getInventoryAndSiteShipmentsDetailsForDigitalTwin = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getInventoryAndSiteShipmentsForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

//inventory and site shipment togther count in digital twin
export const getInventoryAndSiteShipmentsCount = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getInventoryAndSiteShipmentsCountForDigitalTwin`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};



export const getSiteRiskDetailByCountryAndSiteFilter = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getSiteRiskDetailByCountryAndSiteFilter`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// list of UniqueSites
export const getAllUniqueSites = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getAllUniqueSites`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// get Site Impact Details By Country
export const getSiteImpactDetailsByCountry = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getSiteImpactDetailsByCountry`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};


// get Potential Impact Detais
export const getPotentialImpactDetails = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getPotentialImpactDetais`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};

// get Site Impact Details By Country and Sites
export const getSiteImpactDetailsByCountryAndSites = (body_data) => {
  return AjaxService.post(
    `${CLIENT3_A_DEV_DEMO}/api/siteprofile/getSiteImpactDetailsByCountryAndSites`,
    JSON.stringify(body_data)
  ).then(
    (res) => res,
    (error) => {
      throw error.response.data;
    }
  );
};