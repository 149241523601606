import {
    API_CUBIX_URL,
    CLIENT3_A,
    CLIENT3_A_DEV_DEMO,
    API_RISK_ALERT,
    API_RISK_ALERT_MONGO,
} from "../../constants/apiBaseUrl";
import { AjaxService } from "../../utils/AjaxService";

// Study Detail Controller

//get StudyDesignComplexity
export const getStudyDesignComplexity = (_queryParam) => {
    return AjaxService.get(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/getStudyDesignComplexity?studyId=${_queryParam}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//update StudyDesignComplexity
export const updateStudyDesignComplexity = (body_data) => {
    return AjaxService.post(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/saveAndUpdateStudyDesignComplexity`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};


//get product Complexity data
export const getProductComplexityData = (_queryParam) => {
    return AjaxService.get(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/getProductComplexityByStudy?studyId=${_queryParam}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//get supply Complexity data
export const getSupplyComplexityData = (_queryParam) => {
    return AjaxService.get(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/getSupplyComplexityByStudy?studyId=${_queryParam}`,
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//get supply Complexity data
export const updateOverAllRiskInComplexity = (body_data) => {
    return AjaxService.post(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/updateOverAllRisk`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//save the edited product Complexity data
export const saveProductComplexity = (body_data) => {
    return AjaxService.post(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/saveProductComplexity`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};

//save the edited supply Complexity data
export const saveSupplyComplexity = (body_data) => {
    return AjaxService.post(
        `${CLIENT3_A_DEV_DEMO}/api/basicstudydetails/saveSupplyComplexity`,
        JSON.stringify(body_data)
    ).then(
        (res) => res.data,
        (error) => {
            throw error.response.data;
        }
    );
};
