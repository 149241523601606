import { API_CUBIX_URL, CLIENT3_A, API_AUTHENTICATION_URL } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";

export const getAllHotTopic = (_queryParam) => {
  return AjaxService.get(
    `${CLIENT3_A}/api/HotTopics/getAllHotTopics`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getHotTopicById = (id) => {
  return AjaxService.get(
    `${CLIENT3_A}/api/HotTopics/getHottopicById?id=${id}`
    // JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const saveHotTopicsForm = (_queryParam) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/HotTopics/saveHottopics`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const updateHotTopicsForm = (_queryParam) => {
  return AjaxService.post(
    `${CLIENT3_A}/api/HotTopics/EditHottopics`,
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//getAllCompoundNames
export const getAllCompoundNames = () => {
  return AjaxService.get(`${CLIENT3_A}/api/HotTopics/getAllCompoundNames`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

// getAllStudyNames
export const getAllStudyNames = () => {
  return AjaxService.get(`${CLIENT3_A}/api/HotTopics/getAllStudyNames`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

// getallusers
export const getAllUsers = () => {
  return AjaxService.get(
    `${API_AUTHENTICATION_URL}/api/user/allHotTopicUsers`
  ).then(
    (res) => res.data,

    (error) => {
      throw error.response.data;
    }
  );
};
//get profile country

export const getAllCountryByProfile = () => {
  return AjaxService.get(
    `${CLIENT3_A}/api/countryprofile/getAllCountryProfiles`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//delete file
export const getDeleteFile = (fileName) => {
  return AjaxService.delete(`${CLIENT3_A}/file/delete/${fileName}`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

//DownloadFile file
export const getDownloadFile = (fileName) => {
  return AjaxService.get(`${CLIENT3_A}/file/download/${fileName}`).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBCSRTableDataAPI = (_queryParam) => {
  return AjaxService.get(`${CLIENT3_A}/api/HotTopics/getAllHotTopicsBcsr`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getBCSRDataByIDAPI = (id) => {
  return AjaxService.get(`${CLIENT3_A}/api/HotTopics/getHottopicBcsrById?id=${id}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const addBCSRFormDataAPI = (_queryParam) => {
  return AjaxService.post(`${CLIENT3_A}/api/HotTopics/saveHotTopicsBcsr`,
  _queryParam
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const updateBCSRDataByIDAPI = (_queryParam) => {
  return AjaxService.post(`${CLIENT3_A}/api/HotTopics/UpdateHottopicsBcsr`,
   _queryParam
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getcompoundIdbasedOnstudyId = (studyId) => {
  return AjaxService.get(`${CLIENT3_A}/api/HotTopics/getCompoundByStudy?studyId=${studyId}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAllStudyNamesbasedOnCompoundsId = (CompoundsId) => {
  return AjaxService.get(`${CLIENT3_A}/api//HotTopics/getStudiesByCompound?compoundId=${CompoundsId}`
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};