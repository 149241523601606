import { API_AUTHENTICATION_URL } from "../constants/apiBaseUrl";
import { AjaxService } from "../utils/AjaxService";

export const userLogin = (_queryParam) => {
  return AjaxService.post(
    //"https://apigateway.relevancelab.com/prismmicro-authentication/signin",
   API_AUTHENTICATION_URL + "/signin",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const userRefresh = (_queryParam) => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/signin/refresh",
    {},
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      refreshToken: _queryParam,
    }
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const userLogout = (_queryParam) => {
  return AjaxService.post(
    API_AUTHENTICATION_URL + "/signout",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getUserRoles = (auth,_queryParam) => {
  return AjaxService.get(
    API_AUTHENTICATION_URL + "/user/getUserRoles",
    _queryParam,
    {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: auth,
    }
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getUserConfigByRolesApi = (_queryParam) => {
  return AjaxService.get(
     `${ API_AUTHENTICATION_URL }/user/getallusersByRoles`,
      _queryParam,
  ).then(
      (res) => res,
      (error) => {
          throw error.response.data;
      }
  );
};

export const getApp = (_queryParam) => {
  return AjaxService.get(
   API_AUTHENTICATION_URL + `/api/user/getApp`,
   
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getAppPermission = (_queryParam) => {
  return AjaxService.get(
    //"https://apigateway.relevancelab.com/prismmicro-authentication/signin",
   API_AUTHENTICATION_URL + `/api/user/getAppPermission/${_queryParam.appId}`,
   
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const getUserDetailsByToken = (_queryParam) => {
  return AjaxService.get(
   API_AUTHENTICATION_URL + `/api/user/getUserDetailsByToken?token=${_queryParam.token}`,
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};

export const updateLockedProfile = (_queryParam) => {
  return AjaxService.put(
   API_AUTHENTICATION_URL + "/api/user/updateUserDetails",
    JSON.stringify(_queryParam)
  ).then(
    (res) => res.data,
    (error) => {
      throw error.response.data;
    }
  );
};