//RL server						
export const API_CUBIX_URL ="http://192.168.101.78:8765/client";												
export const API_BASE_URL = "https://apigateway.relevancelab.com";						
export const API_RESULTSET_URL = "http://rigel-api-gateway-dev-demo.relevancelab.com";						
export const API_ENTITLEMENT_URL = "https://apigateway.relevancelab.com/entitlement";						
export const API_DASHBOARD_URL = "https://apigateway.relevancelab.com/prismmicro-dashboard";						
export const API_AUTOCOMPLETE_URL = "https://swagger.mareana.com/v1.0";						
//export const API_AUTHENTICATION_URL = "https://apigateway.relevancelab.com/prismmicro-authentication"						
//export const API_AUTHENTICATION_URL = "http://192.168.101.78:8765/prismmicro-authentication"						
export const API_SEARCH_URL = "https://apigateway.relevancelab.com/prismmicro-search";						
export const API_LOGIN_URL = "https://cubix-service.relevancelab.com/rigel-mongo-auth";						
					
						
						
//export const API_AUTHENTICATION_URL = "http://192.168.101.78:9001";						
export const API_AUTHENTICATION_URL = "https://axiom-services.relevancelab.com/rigel-mongo-auth";						
// export const API_AUTHENTICATION_URL = "https://axiom-services.relevancelab.com/rigel-mongo-auth";						
						
//export const CLIENT3_A="http://192.168.101.78:8765/spectra-csc"						
export const CLIENT3_A="https://axiom-services.relevancelab.com/rigel-csc"					
		
export const CLIENT3_A_DEV_DEMO = "https://axiom-services.relevancelab.com/rigel-csc"

//export const API_ALERT="http://192.168.101.78:9001"						
export const API_ALERT="https://axiom-services.relevancelab.com/rigel-mongo-alert" 
//https://alert-services-demo.axiom-bi.com					
						
// export const APP_AUTH="https://demo.axiom-bi.com/"					
// export const APP_AUTH="https://axiom-services.relevancelab.com/rigel-mongo-auth"					
export const APP_AUTH="https://axiom-auth-demo.relevancelab.com/user/login"				
export const API_RESULTSET_URL_1 = "https://apigateway.relevancelab.com/prism-resultset";						

export const API_RIGEL_DEV_DEMO = "https://axiom-services.relevancelab.com"	
						
// export const API_RIGEL_DROOLS="http://192.168.101.55:8765/rigel-drools"
export const API_RIGEL_DROOLS= "https://rules-services-demo.axiom-bi.com";


export const API_RISK_ALERT="https://axiom-services.relevancelab.com/rigel-mongo-alert"
export const API_RISK_ALERT_MONGO ="https://axiom-services.relevancelab.com/rigel-mongo-alert"
// export const API_RISK_ALERT="http://localhost:9009"


// ---------------------------------------------------